/* eslint-disable jsx-a11y/anchor-is-valid */
import "@stream-io/video-styling/dist/css/styles.css";
import "stream-chat-react/dist/css/v2/index.css";
import "../style/index.scss";
import { ComponentType } from "react";
import Head from "next/head";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { StreamTheme } from "@stream-io/video-react-sdk";
import { SettingsProvider } from "../context/SettingsContext";
import { AppEnvironmentProvider } from "../context/AppEnvironmentContext";
import { CookiesProvider } from "react-cookie";

type AppProps = {
  Component: ComponentType;
  pageProps: {};
};

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GA_ID = process.env.NEXT_PUBLIC_GA_ID;

export default function App({
  Component,
  pageProps: { ...pageProps },
}: AppProps) {
  return (
    <>
      <Head>
        <title>Kuyd Meeting</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <AppEnvironmentProvider>
        <CookiesProvider>
          <SettingsProvider>
            <StreamTheme>
              <Component {...pageProps} />
            </StreamTheme>
          </SettingsProvider>
        </CookiesProvider>
      </AppEnvironmentProvider>
      {GTM_ID && <GoogleTagManager gtmId={GTM_ID} />}
      {GA_ID && <GoogleAnalytics gaId={GA_ID} />}
    </>
  );
}
